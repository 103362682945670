<template>
  <div class="">
    <el-row type="flex" class="row-bg" justify="end">
      <el-col :span="5">
        <el-link type="danger" :underline="false" @click="jumpProcessNode"
          >无合适节点？去新建</el-link
        >
      </el-col>
    </el-row>
    <el-form
      :model="params"
      ref="params"
      :rules="rules"
      label-width="80px"
      style="margin-left: 20%; width: 55%"
      class="addDailog"
    >
      <el-form-item label="流程名称" prop="nodeName">
        <el-input
          type="text"
          v-model="params.nodeName"
          maxlength="32"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="流程描述" prop="intro">
        <el-input type="text" v-model="params.intro" show-word-limit></el-input>
      </el-form-item>
      <!-- <el-form-item label="是否评估" prop="evaluate">
        <el-radio v-model="params.evaluate" label="1">是</el-radio>
        <el-radio v-model="params.evaluate" label="0">否</el-radio>
      </el-form-item> -->
      <draggable
        v-model="params.arr"
        chosenClass="chosen"
        forceFallback="true"
        group="people"
        animation="1000"
        @update="datadragRecite"
        @start="onStart"
        @end="onEnd"
      >
        <transition-group>
          <div
            class="addFrom"
            v-for="(item, index) in params.arr"
            v-bind:key="index"
          >
            <el-form-item label="流程节点" prop="region">
              <el-autocomplete
                class="inline-input"
                v-model="item.content"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                value-key="name"
                @input="searchNode(index, item)"
                @select="selectNode($event)"
              ></el-autocomplete>
            </el-form-item>
            <el-button
              style="height: 40px"
              :disabled="params.arr.length == 1 ? true : false"
              @click="buttonDel(index)"
              >-</el-button
            >
            <el-button
              style="height: 40px"
              :disabled="item.content == '' ? true : false"
              @click="buttonAdd(index)"
              >+</el-button
            >
          </div>
        </transition-group>
      </draggable>
    </el-form>
  </div>
</template>

<script>
import { findProjectLibrary, findOneLibrary } from "../../api/processLibrary";
import autocomplete from "./autocomplete.vue";
import draggable from "vuedraggable";
export default {
  name: "edit",
  props: ["msPd"],
  data() {
    return {
      rules: {},
      restaurants: [],
      params: {
        nodeName: "",
        intro: "",
        arr: [{ content: "", id: "" }],
        // evaluate: "1",
      },
      selectInput: 0,
      draggableIndex: null,
      drag: false,
    };
  },
  computed: {},
  methods: {
    jumpProcessNode() {
      let routeUrl = this.$router.resolve({
        path: "/processNode",
        query: { type: "fromProcessLibrary" },
      });
      window.open(routeUrl.href, "_blank");
    },
    //开始拖拽事件
    onStart(evt) {
      this.drag = true;
      // this.draggableIndex = evt.oldIndex;
      console.log("拖动前的索引 :" + evt.oldIndex);
    },
    //拖拽结束事件
    onEnd(evt) {
      this.drag = false;
      console.log("拖动后的索引 :" + evt.newIndex);
    },
    datadragRecite(evt) {
      console.log(evt);
      // console.log("拖动前的索引 :" + evt.oldIndex);
      // console.log("拖动后的索引 :" + evt.newIndex);
      // // this.draggableIndex = evt.newIndex;
    },
    selectNode(e) {
      this.params.arr[this.selectInput].id = e.id;
    },
    searchNode(index, item) {
      this.selectInput = index;
    },
    querySearch(queryString, cb) {
      let restaurants;
      findProjectLibrary({}).then((res) => {
        if (res.success) {
          restaurants = res.data;
          var results = restaurants.filter((i) => {
            if (i.name.indexOf(queryString) != -1) {
              var lshi = [];
              lshi.push({ content: i.name, id: i.id });
              return lshi;
            }
          });
          // 调用 callback 返回建议列表的数据
          this.$forceUpdate();
          cb(results);
        }
      });
    },
    objProject(val) {
      //判断是否回显
      findProjectLibrary({}).then((res) => {
        if (res.success) {
          this.restaurants = res.data;
        }
      });
      if (val) {
        this.params.nodeName = val.name;
        // this.params.evaluate = String(val.evaluate);

        this.params.intro = val.intro;
        this.params.id = val.id;
        this.params.arr = [];
        val.nodeList &&
          val.nodeList.forEach((i) => {
            this.params.arr.push({ content: i.nodeName, id: i.nodeId });
          });
      } else {
        this.params.nodeName = null;
        // this.params.arr = [{ content: "", id: "" }];
      }
    },
    resetForm() {
      this.$refs["params"].resetFields();
    },
    handleClick(row) {
      //添加节点
      // console.log(row);
    },
    buttonDel(index) {
      //删除节点
      this.params.arr.splice(index, 1);
    },
    buttonAdd(index) {
      //点击添加流程节点
      this.params.arr.splice(index + 1, 0, { content: "" });
    },

    handleSelect(item) {
      console.log(item);
    },
  },
  created() {
    this.objProject(this.msPd);
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  watch: {
    msPd(n, o) {
      //监听新增|编辑传过来的对象
      //箭头函数  不然会发生this改变
      this.objProject(n);
    },
    deep: true,
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {
    draggable,
  },
};
</script>

<style scoped>
.addFrom {
  text-align: center;
  display: flex;
}
.addFrom >>> .el-autocomplete {
  width: 90% !important;
}
.active {
  color: black;
  font-weight: bold !important;
}
/*被拖拽对象的样式*/
.item {
  padding: 6px;
  background-color: #fdfdfd;
  border: solid 1px #eee;
  margin-bottom: 10px;
  cursor: move;
}
/*选中样式*/
.chosen {
  /* border: solid 2px #3089dc !important; */
  color: black;
  font-weight: bold !important;
}
.row-bg {
  margin-bottom: 10px;
}
</style>